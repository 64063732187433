import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Panorama from '../components/Panorama'

export default function NotFoundPage() {
  return (
    <Layout>
      <Panorama
        title="Geen paniek, check of je geen typfouten hebt gemaakt of ga terug naar home"
        date="Hmmm, deze pagina bestaat niet"
        location="Onvindbaar"
        time="4:04"
        price="€4,04"
        url="/"
        buttonLabel="Terug naar home"
        primary
      />
    </Layout>
  )
}

export const Head = () => <SEO title="Pagina niet gevonden" />
